<template>
    <comma-seperated-tns @on-change="onChange"/>
    <br/>
    Result: {{ result }}
</template>
<script lang="ts">
import { Options, Vue}  from 'vue-class-component';
import CommaSeperatedTns from '@/components/CommaSeperatedTns.vue';

@Options({
  components: { CommaSeperatedTns },
  data(){
      return {
          result: ""
      }
  }, 
  methods: {
      onChange(val: any){
          this.result = JSON.stringify(val, null, 2);
      }
  }

})
export default class TestCommaSeperatedTns extends Vue {}
</script>