import { render } from "./TestCommaSeperatedTns.vue?vue&type=template&id=2eb45795"
import script from "./TestCommaSeperatedTns.vue?vue&type=script&lang=ts"
export * from "./TestCommaSeperatedTns.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "2eb45795"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2eb45795', script)) {
    api.reload('2eb45795', script)
  }
  
  module.hot.accept("./TestCommaSeperatedTns.vue?vue&type=template&id=2eb45795", () => {
    api.rerender('2eb45795', render)
  })

}

script.__file = "src/views/TestCommaSeperatedTns.vue"

export default script